function initMenu($, $menu) {
    const menuTrigger = $('.js-mobile-menu-trigger');

    menuTrigger.on('click', () => {
        $menu.toggleClass('mobile-menu--open');
    });

    $(document).on('keydown', (e) => {
        if (e.key === 'Escape') {
            $menu.removeClass('mobile-menu--open');
        }
    });
}

if (window.jQuery) {
    const $ = window.jQuery;

    $(window).on('load', () => {
        const $mobileMenus = $('.mobile-menu');

        $mobileMenus.each((index, mobileMenu) => {
            initMenu($, $(mobileMenu));
        });
    });
}